<template>
  <div>
    <a-card :bordered="false">
      <div class="queryView">
        <div class="queryView-conditions">
          <a-form layout="inline" :form="form" class="form-label-4">
            <a-form-item label="项目">
              <a-select
                class="form-control lg"
                v-decorator="['projectCode']"
                placeholder="请选择"
                allowClear
                showSearch
                :options="projectList"
                :filterOption="filterByPY"
                @change="onSelectProject"
              ></a-select>
            </a-form-item>
            <a-form-item label="班组">
              <a-select
                class="form-control lg test"
                v-decorator="['leaderId']"
                placeholder="请选择"
                allowClear
                showSearch
                :filterOption="filterByPY"
                :options="leaderList"
              ></a-select>
            </a-form-item>
            <a-form-item label="身份证号">
              <a-input class="form-control" v-decorator="['cardId']" placeholder="请输入身份证号" allowClear></a-input>
            </a-form-item>
            <a-form-item label="日期">
              <a-range-picker v-decorator="['date']" />
            </a-form-item>
            <a-form-item>
              <div class="condition-btns">
                <a-button type="primary" @click="doQuery(1)">查询</a-button>
                <a-button type="default" @click="resetAndQuery">重置</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div class="queryView-actions">
          <a-button type="primary" @click="recount">辅工考勤重算</a-button>
        </div>

        <div class="queryView-main">
          <a-table
            class="queryView-table"
            rowKey="id"
            bordered
            :loading="loading"
            :columns="tableColumns"
            :dataSource="dataSource"
            :pagination="false"
            size="middle"
          ></a-table>
          <base-pagination
            :totalCount.sync="totalCount"
            :pageNo.sync="pageNo"
            :pageSize.sync="pageSize"
            @change="pageChange"
            size="small"
          />
        </div>
      </div>
    </a-card>
    <RecountOvertime ref="recountOvertime"></RecountOvertime>
  </div>
</template>

<script>
import { queryViewMixin } from '@/utils/mixin'
import { getProjectList, getLeaderList } from '@/service/getData'
import RecountOvertime from './modules/RecountOvertime'
export default {
  components: {
    RecountOvertime,
  },
  mixins: [queryViewMixin],

  data() {
    const tableColumns = [
      {
        title: '项目名称',
        width: '15%',
        align: 'center',
        dataIndex: 'projectName',
      },
      {
        title: '项目编码',
        width: '6%',
        align: 'center',
        dataIndex: 'projectCode',
      },
      {
        title: '班组',
        width: '6%',
        align: 'center',
        dataIndex: 'leaderName',
      },
      {
        title: '名称',
        width: '6%',
        align: 'center',
        dataIndex: 'name',
      },
      {
        title: '身份证号码',
        width: '15%',
        align: 'center',
        dataIndex: 'cardId',
      },
      {
        title: '考勤日期',
        width: '6%',
        align: 'center',
        dataIndex: 'attendanceDate',
      },
      {
        title: '考勤时长',
        width: '6%',
        align: 'center',
        dataIndex: 'attendanceHours',
      },

      {
        title: '明细',
        width: '12%',
        align: 'center',
        dataIndex: 'detail',
        customRender: (text, row, index) => {
          const list = text ? JSON.parse(text) : []
          if (list.length) {
            return (
              <table class="attendance-table">
                <tr class="bg-gray-300">
                  <td>进场时间</td>
                  <td>出场时间</td>
                </tr>
                {list.map((item) => {
                  return (
                    <tr>
                      <td>{item.inn}</td>
                      <td>{item.out}</td>
                    </tr>
                  )
                })}
              </table>
            )
          }
          return '无'
        },
      },
      {
        title: '状态',
        width: '6%',
        align: 'center',
        dataIndex: 'status',
        customRender: (text, row, index) => {
          // 无考勤的无法修改
          return text === 2 ? (
            <span>{this.translateType(text, 'status')}</span>
          ) : (
            <a
              onClick={() => {
                this.changeStatus(row)
              }}
            >
              {this.translateType(text, 'status')}
            </a>
          )
        },
      },
      {
        title: '考勤类型',
        width: '6%',
        align: 'center',
        dataIndex: 'type',
        customRender: (text, row, index) => {
          return <span>{this.translateType(text, 'type')}</span>
        },
      },
      {
        title: '加班考勤推送',
        width: '6%',
        align: 'center',
        dataIndex: 'sendOa',
        customRender: (text, row, index) => {
          let content = '-'
          if (row.type === 1) {
            // 加班考勤显示，推送状态
            if (row.sendOa === 1) {
              content = '已推送'
            }
            // 有效且未推送，显示推送按钮
            if (row.status === 0 && row.sendOa === 0) {
              content = (
                <a
                  onClick={() => {
                    this.sendOa(row)
                  }}
                >
                  推送
                </a>
              )
            }
          }
          return <span>{content}</span>
        },
      },
    ]
    return {
      form: this.$form.createForm(this),
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      loading: false,
      tableColumns: tableColumns,
      dataSource: [],

      modal: {
        visible: false,
        form: this.$form.createForm(this),
        title: '',
        data: {},
      },

      projectList: [],
      leaderList: [],
    }
  },
  computed: {
    typesMap() {
      return {
        type: [
          { value: 0, label: '正常考勤' },
          { value: 1, label: '加班考勤' },
        ],
        sendOa: [
          { value: 0, label: '未推送' },
          { value: 1, label: '已推送' },
        ],
        status: [
          { value: 0, label: '有效考勤' },
          { value: 1, label: '无效考勤' },
          { value: 2, label: '无考勤' },
          { value: 3, label: '月汇总不计算' },
        ],
      }
    },
  },
  methods: {
    async doQuery(_pageNo = this.pageNo, _pageSize = this.pageSize) {
      this.loading = true
      try {
        const res = await this.$axios.post('/attendance/web/attendance/day/list', {
          ...this.formatTimeRangeParams(this.getPageParams(_pageNo, _pageSize), 'date', {
            format: 'YYYY-MM-DD',
          }),
        })
        const { pageSize, pageNo, totalCount, list } = res.data
        this.totalCount = totalCount
        this.pageSize = pageSize
        this.pageNo = pageNo
        this.dataSource = list
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    async onSelectProject(projectCode) {
      this.form.setFieldsValue({ leaderId: undefined })
      this.leaderList = projectCode ? await getLeaderList(projectCode) : []
    },
    async recount() {
      await this.$refs.recountOvertime.start()
      this.$message.success('操作成功')
      this.doQuery()
    },
    async sendOa(row) {
      await this.$axios.post('/attendance/web/attendance/day/send/auxiliary/overtime', {
        id: row.id,
      })
      this.$message.success('操作成功')
      this.doQuery()
    },
    async changeStatus(row) {
      const nextStatus = Number(!row.status)
      const nextStatusName = nextStatus === 0 ? '有效考勤' : '无效考勤'

      await new Promise((resolve, reject) => {
        this.$confirm({
          title: '确认操作？',
          content: `是否更改考勤状态为"${nextStatusName}"？`,
          onOk: () => {
            resolve()
          },
          onCancel: () => {
            reject(new Error('E_CANCEL'))
          },
        })
      })
      await this.$axios.post('/attendance/web/attendance/day/status/modify', {
        id: row.id,
        status: nextStatus,
      })
      this.$message.success('操作成功')
      this.doQuery()
    },
  },
  async mounted() {
    this.projectList = await getProjectList()
  },
}
</script>

<style lang="less" scoped></style>
